
import Contrasenia from "@/Constantes/Contrasenia";
export default {
  name: "seccion-indicadores-contrasenia-valida",
  props: {
    contrasenia: { type: String, required: true },
  },
  computed: {
    sinEspacios() {
      if (!this.contrasenia) return false;
      if (!Contrasenia.ESPACIO_REGEX.test(this.contrasenia)) return true;
      return false;
    },
    caracteresMinimos() {
      return this.contrasenia.length >= 12;
    },
    incluyeNumeros() {
      return Contrasenia.NUMERO_REGEX.test(this.contrasenia);
    },
    incluyeMayusculas() {
      return Contrasenia.MAYUSCULA_REGEX.test(this.contrasenia);
    },
    incluyeMinusculas() {
      return Contrasenia.MINUSCULA_REGEX.test(this.contrasenia);
    },
    incluyeCarEspeciales() {
      return Contrasenia.CARACTERES_ESPECIALES_REGEX.test(this.contrasenia);
    },
  },
};

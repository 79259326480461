import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "usuarios", "listarGestor"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "usuarios",
      "obtenerConcentradoOpciones"
    ),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "usuarios", "obtenerDetalle"),
  // POST
  agregar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "agregar"),
  editar: (data) => RepoUtils.ejecutarPeticionPost(data, "usuarios", "editar"),
  editarPerfiles: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "editarPerfiles"),
  editarContrasenia: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "editarContrasenia"),
  actualizarStatus: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "actualizarStatus"),
  actualizarFoto: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "actualizarFoto"),
  eliminar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "usuarios", "eliminar"),
};


import UsuarioService from "../../../Services/UsuarioService";
import MenuTelefonoLada from "../../leads/components/MenuTelefonoLada.vue";
import SeccionIndicadoresContraseniaValida from "./components/SeccionIndicadoresContraseniaValida.vue";
import CardUsuarios from "./components/CardUsuarios.vue";
export default {
  name: "ProductoGestor",
  components: {
    MenuTelefonoLada,
    SeccionIndicadoresContraseniaValida,
    CardUsuarios,
  },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Configuración" }, { etiqueta: "Usuarios" }],
      statusOpciones: [
        { value: 200, label: "Activo" },
        { value: 301, label: "Inactivo" },
        { value: 300, label: "Baja" },
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      arrayCardsTotales: [],
      mostrarContrasenia: false,
      ladas: this.constantesLadas.Ladas,
      ladaDefault: "",
      ladaIconoDefault: "",
      perfiles: [],
      usuarios: [],
      totales: {},
      columnas: [
        {
          name: "nombreCorto",
          header: "Usuario",
          align: "left",
          field: "nombre_corto",
          minWidth: 120,
          width: 140,
          templateName: "folio",
          routeName: "usuariosDetalle",
          rutaId: "usuario_id",
        },
        {
          name: "Nombre",
          header: "Nombre",
          align: "left",
          field: "nombre_completo",
          minWidth: 160,
          width: 180,
        },
        {
          name: "email",
          header: "Email",
          align: "left",
          field: "email",
          minWidth: 160,
          width: 180,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 120,
          width: 140,
          templateName: "status",
          statusName: "status_nombre",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 120,
          width: 155,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 130,
          width: 160,
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      agregarUsuarioObj: {
        nombre: "",
        apellidos: "",
        lada: "+52",
        ladaIcono: "https://flagcdn.com/mx.svg",
        telefono: "",
        email: "",
        contrasenia: "",
        contraseniaRepetida: "",
        perfiles: [],
      },
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {
    crearNombreCorto() {
      let nombreCorto = this.agregarUsuarioObj.nombre.split(" ")[0];
      let apellidoCorto = this.agregarUsuarioObj.apellidos.split(" ")[0];
      return nombreCorto + " " + apellidoCorto;
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerUsuarios(false, 1);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s, true), // El segundo parametro es para arrays con valores numericos
        ordenar: "nombre_asc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerUsuarios(loader = true, obtenerTotales = 0) {
      if (loader) this.loader(true);
      this.filtros.obtenerTotales = obtenerTotales;
      this.replaceUrl();
      await UsuarioService.listarGestor(this.filtros)
        .then((resp) => {
          let usuarios = resp.usuarios;
          this.usuarios = usuarios.map((u) => {
            return {
              ...u,
              registro_fecha: new Date(u.registro_fecha),
            };
          });

          // Se setean totales
          if (obtenerTotales) {
            this.totales = resp.totales;
            this.armarArrayCardsTotales();
          }

          // Se configura lada default
          let paisRegionCodigoDefault =
            localStorage.getItem("paisRegionCodigo");
          if (paisRegionCodigoDefault != null) {
            let lada = this.ladas.find(
              (l) => l.alpha3Code == paisRegionCodigoDefault
            );
            this.ladaDefault = "+" + lada.callingCodes[0];
            this.ladaIconoDefault = lada.flag;
          }
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await UsuarioService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.perfiles = resp.perfiles;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async abrirModalAgregar() {
      if (_.isEmpty(this.perfiles)) await this.obtenerConcentradoOpciones();
      this.agregarUsuarioObj.lada = this.ladaDefault;
      this.agregarUsuarioObj.ladaIcono = this.ladaIconoDefault;
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarUsuarioObj = {
        nombre: "",
        apellidos: "",
        lada: "",
        ladaIcono: "",
        telefono: "",
        email: "",
        contrasenia: "",
        contraseniaRepetida: "",
        perfiles: [],
      };
      this.modalAgregar = false;
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
        ordenar: "nombre_asc",
      };
      this.obtenerUsuarios();
      this.accionLimpiar = true;
    },
    async agregarUsuario(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.agregarUsuarioObj,
        nombreCorto: this.crearNombreCorto,
        perfiles: JSON.stringify(this.agregarUsuarioObj.perfiles),
      };
      await UsuarioService.agregar(datos)
        .then(async (resp) => {
          this.cerrarModalAgregar();
          await this.obtenerUsuarios(false, 1);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    seleccionarLada(lada) {
      this.agregarUsuarioObj.ladaIcono = lada.flag;
      this.agregarUsuarioObj.lada = "+" + lada.callingCodes[0];
    },
    armarArrayCardsTotales() {
      this.arrayCardsTotales = [
        { label: "Usuarios creados", valor: this.totales.usuarios_total },
        { label: "Usuarios restantes", valor: 0 }, // Pendiente
        { label: "Usuarios activos", valor: this.totales.usuarios_activos },
        {
          label: "Usuarios inactivos",
          valor: this.totales.usuarios_inactivos,
        },
        { label: "Usuarios baja", valor: this.totales.usuarios_baja },
      ];
    },
  },
};
